<template>
  <div id="app" @click="offMenu">
    <Header :visible.sync="isMenu" @sumbit="open" @openLang="openLang" />
    <router-view />
    <Footer
      v-if="!['/login', '/register'].includes($route.path)"
      @updateHeader="openPopLang"
    />
    <div
      class="langRoom"
      :class="{ pop: footerLang }"
      v-if="langMenus"
      @click.stop
    >
      <div class="langRoom-box">
        <div class="langRoom-row">
          <img src="@/assets/images/lang.svg" />
          <div class="main">
            <span class="text">{{ $t("src.App.911136-0") }}</span>
          </div>
        </div>
        <div class="langRoom-row">
          <img src="@/assets/images/vn.svg" />
          <div class="main">
            <span class="text">Vietnam</span>
            <p>
              <span
                class="setLang"
                v-for="(item, index) in languages"
                :key="index"
                @click="changeLanguageAndCurrenc(item.id, 'set')"
                >{{ item.name }}</span
              >
            </p>
          </div>
        </div>
        <div
          class="langRoom-close el-icon-close"
          v-if="footerLang"
          @click="closeLang"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { mapState } from "vuex";
export default {
  components: { Header, Footer },
  data() {
    return {
      isMenu: false,
      gamePlaytList: null,
      isLange: false,
      langMenus: false,
      footerLang: false,
    };
  },
  computed: {
    ...mapState(["languages"]),
  },
  watch: {
    isLogin(val) {
      if (val) {
        this.getUserInfo();
        this.checkIsSetWidthPassword();
      }
    },
  },
  mounted() {
    this.getRegisterItem();
    this.getWebsiteBasicInfo();
    this.getUserInfo();
    this.checkIsSetWidthPassword();
  },
  methods: {
    // 是否设置支付密码
    checkIsSetWidthPassword() {
      if (!this.isLogin) return;
      this.$Api.checkIsSetWidthPassword().then((res) => {
        if (res) {
          const val = res.msg === "yes" ? true : false;
          this.$store.dispatch("setPayPwdAc", val);
        }
      });
    },
    openLang(val) {
      if (val) {
        this.langMenus = true;
      }
    },
    openPopLang(val) {
      if (val) {
        this.langMenus = true;
        this.footerLang = true;
      }
    },
    closeLang() {
      this.langMenus = false;
      this.footerLang = false;
    },
    offMenu() {
      this.isMenu = true;
      this.langMenus = false;
      this.footerLang = false;
    },
    open(val) {
      if (val) {
        this.isMenu = false;
      }
    },
    // 基础信息
    getWebsiteBasicInfo() {
      this.$Api.getWebsiteBasicInfo().then((res) => {
        if (res) {
          const lang = localStorage.getItem("keywords");
          this.$store.dispatch("setBaseInfoAc", res.websiteBasicList);
          localStorage.setItem("keywords", res.websiteBasicList[2].configValue);
          this.exitIco(res.websiteBasicList);
          this.setTitle(res.websiteBasicList);

          if (!lang) {
            location.reload();
          }
        }
      });
    },
    // 个人资料
    getUserInfo() {
      const isLogin = localStorage.getItem("AppToken");
      if (!isLogin) {
        localStorage.removeItem("AppToken");
        localStorage.removeItem("userInfo");
        return;
      }
      this.$Api.getUserInfo().then((res) => {
        if (res) {
          this.$store.dispatch("setUserInfoAc", res.userInfo);
          if (res.userInfo.languageType) {
            this.changeLanguageAndCurrenc(res.userInfo.languageType);
          }
        }
      });
    },

    changeLanguageAndCurrenc(val, type) {
      let params = {
        languageType: val,
      };
      this.$Api.changeLanguageAndCurrenc(params).then((res) => {
        if (res) {
          let langtarget = this.languages.find((item) => item.id === val).type;
          this.$i18n.locale = langtarget;
          localStorage.setItem("lang", langtarget);
          this.langMenus = false;
          this.footerLang = false;
          if (type) {
            location.reload();
          }
        }
      });
    },
    // 注册事项
    getRegisterItem() {
      this.$Api.getRegisterItem().then((res) => {
        if (res) {
          this.$store.dispatch("setRegisterItemAc", res.registerItemList);
        }
      });
    },
    setTitle(val) {
      document.title = val.find(
        (item) => item.configKey === "title"
      ).configValue;
    },
    exitIco(val) {
      let ico = document.querySelector('link[rel="icon"]');
      let title = document.querySelector("title");
      title.innerHTML = val[0].configValue;
      if (ico !== null) {
        ico.href = val[1].configValue;
      } else {
        ico = document.createElement("link");
        ico.rel = "icon";
        ico.href = val[1].configValue;
        document.head.appendChild(ico);
      }
    },
  },
};
</script>
<style scoped lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.langRoom {
  position: fixed;
  top: 70px;
  right: 10px;
  background: #fff;
  border-radius: 4px;
  padding: 24px;
  width: 280px;
  box-shadow: 5px 5px 5px -2px rgba(0, 0, 0, 0.2);
  z-index: 10;

  &-close {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -12px;
    top: 0;
    transform: translateY(-50%);
    position: absolute;
    background: white;
    box-shadow: 5px 5px 5px -2px rgba(0, 0, 0, 0.2);
    font-size: 18px;
    cursor: pointer;
  }

  &.pop {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;

    .langRoom-box {
      background: #fff;
      border-radius: 4px;
      padding: 24px;
      width: 280px;
      position: relative;
    }
  }

  &-row {
    display: flex;
    align-items: center;

    &:first-child {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #ececec;
    }

    img {
      width: 28px;
      height: 28px;
      border-radius: 14px;
    }

    .main {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-left: 16px;
      text-align: left;
      font-size: 12px;

      .text {
        font-size: 14px;
      }

      p {
        color: #8697a2;
        margin-top: 6px;
      }

      .setLang {
        cursor: pointer;
        padding-right: 8px;
        margin-right: 8px;
        border-right: 1px solid #dcdcdc;

        &:last-child {
          border-right: 0;
          margin-right: 0;
        }
      }
    }
  }
}
</style>
