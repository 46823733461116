import Vue from 'vue'
import VueI18n from 'vue-i18n'
import lang_zh from '@/lang/zh.json'
import lang_hk from '@/lang/hk.json'
import lang_en from '@/lang/en.json'
import lang_vn from '@/lang/vn.json'

Vue.use(VueI18n)
let langKey = localStorage.getItem('keywords') || ''
const langs = [lang_zh, lang_hk, lang_en, lang_vn]
langs.map(item => {
    Object.keys(item).map(key => {
        item[key] = item[key].replace(/KU9/g, langKey)
        console.log(langKey);
    })
})
const i18n = new VueI18n({
    locale: localStorage.getItem('lang') || 'zh',
    messages: {
        zh: { ...langs[0] },
        hk: { ...langs[1] },
        en: { ...langs[2] },
        vn: { ...langs[3] }
    }
})
export default i18n