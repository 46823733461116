<template>
  <div class="header home" @mouseleave="moveMenus">
    <div class="bk8-wid">
      <div class="header-left">
        <div class="menuRoom" @click.stop="offMenu">
          <span :class="{ show: isOpen }"></span>
        </div>
        <div class="logo" @click="$router.push('/')">
          <img v-if="baseInfo" :src="baseInfo[16].configValue" />
          <!-- <img src="@/assets/images/logo.svg" /> -->
        </div>
        <div class="links" v-if="gamePlaytList">
          <ul>
            <li
              v-for="(item, index) in gamePlaytList"
              :class="{
                hover: item.type === ($route.params.gamePlayt || $route.name),
              }"
              :key="index"
              @mouseenter="tabMenus(item)"
              @click.stop="jumpWebMenus(item.link)"
            >
              {{ item.gameTypeName }}
            </li>
          </ul>
        </div>
      </div>
      <!-- 登录注册入口 -->
      <div class="header-right">
        <template v-if="!isLogin">
          <a @click="$router.push('/login')" class="login">{{
            $t("components.Header.656206-0")
          }}</a>
          <a @click="$router.push('/register')" class="register btn-orange">{{
            $t("components.Header.656206-1")
          }}</a>
        </template>
        <template v-if="isLogin && userInfo">
          <div class="level" @click="$router.push('/myaccount/vip')">
            <span class="level-icon"
              ><img src="@/assets/images/userinfo/vip.svg"
            /></span>
            <span class="level-text">{{ userInfo.levelName }}</span>
          </div>
          <div class="level-load">
            <span>{{ userInfo.userName }}</span>
            <p>
              {{ $t("components.Header.656206-2") }}{{ userInfo.joinDays }}
              {{ $t("components.Header.656206-3") }}
            </p>
          </div>
          <div class="photo" @click="$router.push('/myaccount/userinfo')">
            <img
              v-if="userInfo && userInfo.photoUrl"
              :src="userInfo.photoUrl"
              class="photo-img"
            />
          </div>
          <div class="message" @click="$router.push('/myaccount/message')">
            <span class="red" v-if="messageInfo">{{ messageInfo }}</span>
            <img
              src="@/assets/images/userinfo/message.svg"
              class="message-img"
            />
          </div>
          <div class="price" v-if="accountMoney">
            <span class="a-gray">{{ userInfo.currencyType }}</span>
            {{
              accountMoney.userBalance
                ? accountMoney.userBalance.toFixed(2)
                : "0.00"
            }}
          </div>
          <div class="deposit">
            <button
              class="btn-blue"
              @click="$router.push('/myaccount/deposit')"
            >
              {{ $t("components.Header.656206-4") }}
            </button>
            <div class="pop-bg">
              <em></em>
              <ul>
                <li
                  v-for="(item, index) in menulist"
                  :key="index"
                  @click="openLink(item)"
                  :class="{ hover: menulistIndex === item.type }"
                >
                  <img :src="item.icon" class="icon" />
                  <img :src="item.iconHover" class="iconhover" />
                  <span>{{ item.name }}</span>
                </li>
              </ul>
            </div>
          </div>
        </template>
        <div class="lang-select" @click.stop="openLang">
          <div class="name">
            <img src="@/assets/images/vn.svg" class="gq" />
            <span class="arrow"></span>
          </div>
        </div>
      </div>
      <div
        class="header-navs"
        @click.stop="isOpen = true"
        :class="{ isOpen: isOpen }"
      >
        <div class="header-navs-box">
          <div class="header-navs-left">
            <div class="left-row" @click.stop="jumpWeb('/sponsors')">
              <img src="@/assets/images/zzs.svg" class="icon" />
              <span class="text">{{ $t("components.Header.656206-5") }}</span>
            </div>
            <div
              class="left-row"
              @click.stop="jumpWeb('/myaccount/vip', 'isLogin')"
            >
              <img src="@/assets/images/vip.svg" class="icon" />
              <span class="text">VIP</span>
            </div>
            <div class="left-row" @click.stop="jumpWeb('/download')">
              <img src="@/assets/images/download.svg" class="icon" />
              <span class="text">{{ $t("components.Header.656206-6") }}</span>
            </div>
            <div class="header-navs-ul">
              <ul>
                <li
                  v-for="(item, index) in navs"
                  :key="index"
                  @click.stop="jumpWebText(item)"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>

            <button
              class="btn-orange"
              :class="{ 'btn-gray': isLogin }"
              @click.stop="LoginBtn"
            >
              {{
                isLogin
                  ? $t("components.Header.656206-7")
                  : $t("components.Header.656206-0")
              }}
            </button>
          </div>
          <div class="header-navs-right">
            <ul v-if="menus">
              <li
                v-for="(item, index) in menus"
                :key="index"
                @click.stop="jumpWeb(item.link)"
              >
                <img
                  v-if="item.gameType"
                  class="icon"
                  :src="
                    require('@/assets/images/menuicon/' +
                      item.gameType +
                      '.svg')
                  "
                />
                <span class="text">{{ item.gameTypeName }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div
      class="menus-box"
      v-if="targetGameShow && targetGameVal && targetGameVal.length"
    >
      <div class="menus-list">
        <ul>
          <li
            v-for="(item, index) in targetGameVal"
            :class="{ transparent: item.check }"
            :key="index"
            @mouseenter="moveGameIcon(item, index)"
            @click="openGame(item)"
          >
            <img v-if="item.imgUrl" :src="item.imgUrl" />
            <img v-else src="@/assets/images/casino-wm.png" />
            <p>{{ item.platType + " " + targetGameType }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { removeToken } from "@/utils/token";
import { mapState } from "vuex";
export default {
  name: "Header",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      navs: [
        {
          name: this.$t("components.Header.656206-8"),
          link: "/about",
        },
        {
          name: this.$t("components.Header.656206-9"),
          link: "/contact-link",
          black: true,
        },
        {
          name: this.$t("components.Header.656206-10"),
          link: "/info-center/faq#general",
        },
        {
          name: this.$t("components.Header.656206-11"),
          link: "/contact",
        },
        {
          name: this.$t("components.Header.656206-12"),
          link: "/responsible-gaming",
        },
        {
          name: this.$t("components.Header.656206-13"),
          link: "/info-center/tnc#terms",
        },
        // {
        //     name: '代理',
        //     link: ''
        // }
      ],
      menulistIndex: "deposit",
      menus: null,
      gamePlaytList: null,
      menulist: [
        {
          name: this.$t("components.Header.656206-4"),
          icon: require("@/assets/images/userinfo/deposit.svg"),
          iconHover: require("@/assets/images/userinfo/deposit-1.svg"),
          link: "/myaccount/deposit",
          type: "deposit",
        },
        {
          name: this.$t("components.Header.656206-14"),
          icon: require("@/assets/images/userinfo/transfer.svg"),
          iconHover: require("@/assets/images/userinfo/transfer-1.svg"),
          link: "/myaccount/transfer",
          type: "transfer",
        },
        {
          name: this.$t("components.Header.656206-15"),
          icon: require("@/assets/images/userinfo/withdraw.svg"),
          iconHover: require("@/assets/images/userinfo/withdraw-1.svg"),
          link: "/myaccount/withdrawal",
          type: "withdrawal",
        },
        {
          name: this.$t("components.Header.656206-16"),
          icon: require("@/assets/images/userinfo/history.svg"),
          iconHover: require("@/assets/images/userinfo/history-1.svg"),
          link: "/myaccount/betRecord",
          type: "betRecord",
        },
      ],
      targetGameVal: null,
      targetGameShow: false,
      targetGameType: "",
    };
  },
  watch: {
    visible(val) {
      if (val) {
        this.isOpen = false;
      }
    },
    isLogin(val) {
      if (val) {
        this.getUserInfo();
      }
    },
    $route() {
      this.initInfo();
    },
  },
  computed: {
    ...mapState(["gameLink", "langMenus", "languages", "isLogin"]),
  },
  mounted() {
    this.getGamePlayt();
    this.getUserInfo();
    this.getUnReadMessageCount();
  },
  methods: {
    openLink(val) {
      if (["deposit", "withdrawal"].includes(val.type)) {
        this.judgeMoblieOrPayPwd(val.link);
        return;
      }
      this.$router.push(val.link);
    },
    openLang() {
      this.$emit("openLang", true);
    },
    tabLang(val) {
      if (!this.isLogin) {
        this.$confirm(
          this.$t("components.Header.656206-17"),
          this.$t("components.Header.656206-18"),
          {
            confirmButtonText: this.$t("components.Header.656206-19"),
            showCancelButton: false,
            customClass: "el-pop-wid",
          }
        )
          .then(() => {
            this.$router.push("/register");
          })
          .catch(() => {
            console.log(11);
          });
        this.$store.dispatch("setLangMenusAc", false);
        return;
      }
      let params = {
        languageType: val.id,
      };
      this.$Api.changeLanguageAndCurrenc(params).then((res) => {
        if (res) {
          let langtarget = this.languages.find(
            (item) => item.id === val.id
          ).type;
          this.$i18n.locale = langtarget;
          localStorage.setItem("lang", langtarget);
          location.reload();
        }
      });
    },
    openGame(val) {
      if (!this.isLogin && !["fish", "esports"].includes(val.route)) {
        this.$confirm(
          this.$t("components.Header.656206-17"),
          this.$t("components.Header.656206-18"),
          {
            confirmButtonText: this.$t("components.Header.656206-19"),
            showCancelButton: false,
            customClass: "el-pop-wid",
          }
        )
          .then(() => {
            this.$router.push("/login");
          })
          .catch(() => {
            console.log(11);
          });
        return;
      }
      if (["fish", "esports"].includes(val.route)) {
        this.$router.push("/game/" + val.route + "/" + val.platType);
        this.targetGameShow = false;
        return;
      }
      this.targetGameShow = false;
      this.$loading.show();
      const params = {
        platType: val.platType,
        gameType: val.gameType,
        gameCode: val.gameCode,
        devices: 0,
      };
      this.$Api.getGameUrl(params).then((res) => {
        this.$loading.hide();
        if (res) {
          const url = JSON.parse(res.data);
          window.open(url.url, "_blank");
        }
      });
    },
    moveMenus() {
      this.targetGameShow = false;
    },
    moveGameIcon(val) {
      this.targetGameVal.forEach((item) => {
        item.check = true;
        if (val.platType === item.platType) {
          item.check = false;
        }
      });
    },
    tabMenus(val) {
      this.targetGameVal = this.gamePlaytList.find(
        (item) => item.type === val.type
      ).list;
      this.targetGameType = val.gameTypeName;
      this.targetGameShow = true;
    },
    initInfo() {
      this.menulistIndex = this.$route.meta.layout;
    },
    // 消息汇总
    getUnReadMessageCount() {
      if (!this.isLogin) return;
      this.$Api.getUnReadMessageCount().then((res) => {
        if (res) {
          const message =
            res.data.totalCount > 99 ? "99+" : res.data.totalCount;
          this.$store.dispatch("setMessageInfoAc", message);
        }
      });
    },
    // 退出
    quipost() {
      this.$Api.logout().then((res) => {
        if (res) {
          removeToken();
          this.$store.dispatch("setUserInfoAc", false);
          location.reload();
        }
      });
    },
    // 账户money
    getUserBalance() {
      this.$Api.getUserBalance().then((res) => {
        if (res) {
          this.$store.dispatch("setAccountMoneyAc", res.userBalanceMap);
        }
      });
    },

    LoginBtn() {
      if (this.isLogin) {
        this.quipost();
      } else {
        this.jumpWeb("/login");
      }
    },
    // 个人资料接口
    getUserInfo() {
      if (!this.isLogin) return;
      this.$Api.getUserInfo().then((res) => {
        if (res) {
          this.$store.dispatch("setUserInfoAc", res.userInfo);
          this.getUserBalance();
        }
      });
    },
    jumpWebMenus(val) {
      this.$router.push(val);
      this.isOpen = false;
    },
    jumpWeb(val, isLogin) {
      if (isLogin) {
        this.isLoginTip(val);
        this.offMenu();
        return;
      }
      this.$router.push(val);
      this.offMenu();
    },
    jumpWebText(val) {
      if (val.black) {
        window.open(
          this.baseInfo[6].configValue,
          "_blank",
          "menubar=no,location=no,width=540,height=700,top=10,left=100"
        );
      } else {
        this.$router.push(val.link);
      }
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.$emit("sumbit", true);
      }
    },
    offMenu() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.$emit("sumbit", true);
      }
    },
    // 平台
    getGamePlayt() {
      this.$Api.getApiGameCategoryList().then((res) => {
        if (res) {
          const arr = res.apiGameCategoryList.filter(
            (item) => item.gameType !== "0"
          );
          const arrSelect = res.apiGameCategoryList.filter(
            (item) => item.gameType !== "0"
          );
          arr.push(
            {
              gameType: "activity",
              gameTypeName: this.$t("components.Header.656206-20"),
              link: "/activity",
              type: "activity",
            }
            // { gameType: 'vip', gameTypeName: 'VIP', link: '/vip', type: 'vip' }
          );
          arr.forEach((item) => {
            this.gameLink.find((row) => {
              if (row.number === item.gameType) {
                item.type = row.type;
                item.link = "/" + row.type;
                item.gameTypeName = row.name;
                item.check = false;
              }
            });
          });
          arrSelect.push({
            gameType: "activity",
            gameTypeName: this.$t("components.Header.656206-20"),
            link: "/activity",
            type: "activity",
          });
          arrSelect.unshift({
            gameType: "home",
            gameTypeName: this.$t("components.Header.656206-21"),
            link: "/",
            type: "home",
          });
          arrSelect.forEach((item) => {
            this.gameLink.find((row) => {
              if (row.number === item.gameType) {
                item.type = row.type;
                item.link = "/" + row.type;
              }
            });
          });
          this.menus = arrSelect;

          this.gamePlaytList = arr;
          this.gamePlaytList.forEach((item) => {
            if (["1", "2", "3", "4", "5", "6", "7"].includes(item.gameType)) {
              this.getAllPlatTypes(item.gameType);
            }
          });
        }
      });
    },
    // 游戏
    getAllPlatTypes(val) {
      const params = {
        gameType: val,
        device: 1,
      };
      this.$Api.getAllPlatTypes(params).then((res) => {
        if (res) {
          const arr = [];
          Object.keys(res.platTypes).map((key) => {
            arr.push(res.platTypes[key]);
          });
          arr.forEach((item) => {
            item.check = false;
            item.route = this.gamePlaytList.find(
              (item) => item.gameType === val
            ).type;
          });
          this.gamePlaytList.forEach((item) => {
            if (item.gameType === val) {
              item.list = arr;
              item.link =
                "/game/" +
                item.type +
                "/" +
                (["2", "6"].includes(val) ? "all" : arr[0].platType);
            }
          });
          this.$store.dispatch("setGamePlaytAc", this.gamePlaytList);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  position: relative;

  .bk8-wid {
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: #ffffffdb;
    backdrop-filter: blur(10px);
    height: 70px;
  }

  &.home {
    z-index: 11;
    position: relative;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.06);
  }

  &-left {
    display: flex;
    align-items: center;
  }

  .logo {
    margin: 0 16px;
    cursor: pointer;

    img {
      height: 40px;
    }
  }

  .menuRoom {
    position: relative;
    display: flex;
    align-items: center;
    width: 44px;
    height: 44px;
    cursor: pointer;

    span {
      width: 26px;
      height: 2px;
      background: linear-gradient(90deg, #73c8ec, #3968c5);
      border-radius: 3px;
      display: block;
      position: relative;

      &::before,
      &::after {
        content: "";
        position: absolute;
        height: 2px;
        background: linear-gradient(90deg, #73c8ec, #3968c5);
        border-radius: 3px;
        left: 0;
        width: 26px;
        top: 50%;
        transition: all 0.4s ease-in-out;
      }

      &::before {
        transform: translateX(3px) translateY(-9px);
      }

      &::after {
        transform: translateX(3px) translateY(7px);
      }

      &.show {
        &::before {
          transform: translateX(0) translateY(-9px);
        }

        &::after {
          transform: translateX(0) translateY(7px);
        }
      }
    }
  }

  &-right {
    color: #0f161f;
    display: flex;
    align-items: center;

    .login {
      color: #0f161f;
      text-decoration: none;
      font-size: 16px;
      margin-right: 14px;
      cursor: pointer;
      white-space: nowrap;
    }

    .register {
      background: #ff8a00;
      color: #fff;
      border-radius: 6px;
      font-size: 16px;
      height: 36px;
      padding: 0.05rem 0.15rem;
      margin: 0 0.15rem;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 94px;
      text-decoration: none;
      cursor: pointer;
      white-space: nowrap;
    }
  }
}

.links {
  ul {
    display: flex;
    align-items: center;

    li {
      font-size: 15px;
      margin-left: 20px;
      height: 70px;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: color 0.4s ease-in-out;

      &:hover,
      &.hover {
        color: #ff8a00;
      }
    }
  }
}

.header-navs {
  display: block;
  position: absolute;
  background: #fff;
  top: 70px;
  box-shadow: 0 5px 8px -2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 10;
  max-height: 0;
  transition: max-height 0.75s ease-in-out;
  overflow: hidden;

  &-box {
    display: flex;
    padding: 35px 55px 50px;
    width: 654px;
    justify-content: space-between;
  }

  &-left,
  &-right {
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
  }

  &-left {
    border-right: 1px solid #f7f7f7;

    .btn-orange {
      width: 209px;
    }
  }

  &-right {
    padding-left: 77px;

    ul {
      li {
        margin: 0 0 24px 0;
        font-size: 14px;
        color: #0f161f;
        display: flex;
        align-items: center;
        cursor: pointer;
        text-transform: capitalize;

        &:hover {
          color: #ff8a00;
        }

        .icon {
          width: 32px;
          height: 32px;
          margin: 0 12px 0 0;
        }
      }
    }
  }

  &-ul {
    margin: 80px 60px 60px 0;

    ul {
      font-size: 13px;
      clear: both;
      color: #8697a2;
      display: flex;
      flex-direction: column;

      li {
        list-style: disc;
        margin-bottom: 14px;
        float: left;
        margin: 0 15px 10px 0;
        list-style-position: inside;
        cursor: pointer;
        text-align: left;

        &:hover {
          color: #ff8a00;
        }

        &::marker {
          font-size: 12px;
          unicode-bidi: isolate;
          font-variant-numeric: tabular-nums;
          text-transform: none;
          text-indent: 0px !important;
          text-align: start !important;
          text-align-last: start !important;
        }
      }
    }
  }

  .left-row {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 28px;

    &:hover {
      color: #ff8a00;
    }

    .icon {
      width: 38px;
      height: 38px;
      margin-right: 14px;
    }

    .text {
      font-size: 18px;
    }
  }

  &.isOpen {
    max-height: 640px;
  }
}

.level {
  display: flex;
  align-items: center;
  background: #0693be;
  border-radius: 20px;
  padding: 0 10px 0 0;
  margin: 10px 0;
  width: unset;
  color: white;
  font-size: 12px;
  cursor: pointer;

  &-icon {
    width: 24px;
    height: 24px;
    background: #fdb813;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;

    img {
      width: 60%;
    }
  }

  &-text {
    padding-left: 6px;
  }

  &-load {
    font-size: 12px;
    text-align: left;
    line-height: 1;
    margin-left: 8px;

    span {
      color: #0071e3;
      margin-bottom: 2px;
      display: block;
    }

    p {
      color: #8697a2;
    }
  }
}

.photo {
  background: #ff8a00;
  padding: 0;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  position: relative;

  &:hover {
    .pop-bg {
      display: block;
    }
  }

  &-img {
    width: 100%;
    cursor: pointer;
  }
}

.message {
  margin-left: 8px;
  position: relative;

  &-img {
    width: 28px;
    cursor: pointer;
  }

  .red {
    min-width: 16px;
    height: 16px;
    font-size: 12px;
    color: white;
    border-radius: 100%;
    background: red;
    position: absolute;
    right: -4px;
    display: block;
    top: -4px;
  }
}

.price {
  margin-left: 12px;
  padding-left: 12px;
  border-left: 1px solid #7c7c7c;
}

.deposit {
  margin-left: 8px;
  position: relative;

  .btn-blue {
    height: 36px;
    padding: 0 16px;
    border-radius: 6px;
  }

  &:hover {
    .pop-bg {
      display: block;
    }
  }
}

.pop-bg {
  top: 44px;
  position: absolute;
  right: 0;
  background: white;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  padding: 10px;
  min-width: 200px;
  display: none;

  &.pop-align-center {
    right: auto;
    left: 50%;
    transform: translateX(-50%);

    em {
      right: auto;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &::before {
    content: "";
    top: -12px;
    height: 12px;
    left: 0;
    right: 0;
    background: transparent;
    display: block;
    position: absolute;
  }

  em {
    border: 8px solid transparent;
    border-bottom-color: white;
    border-top: none;
    position: absolute;
    right: 16px;
    top: -8px;
  }

  ul {
    li {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      background: #f7f7f7;
      border-radius: 20px;
      padding: 4px 16px;
      cursor: pointer;

      &:hover,
      &.hover {
        background: #ff8a00;
        color: #f7f7f7;

        .icon {
          display: none;
        }

        .iconhover {
          display: block;
        }
      }

      .icon,
      .iconhover {
        height: 28px;
      }

      .iconhover {
        display: none;
      }

      span {
        margin-left: 10px;
      }
    }
  }
}

.menus-box {
  position: fixed;
  background: rgba(0, 15, 76, 0.8);
  backdrop-filter: blur(5px);
  width: 100%;
  height: auto;
  padding: 30px;
  top: 70px;
  left: 0;
  right: 0;

  .menus-list {
    width: 1100px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    ul {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      flex-wrap: wrap;

      li {
        width: 134px;
        margin: 10px;

        &.transparent {
          opacity: 0.5;
        }

        img {
          width: 100%;
        }

        p {
          color: white;
          text-transform: uppercase;
        }
      }
    }
  }
}

.lang-select {
  background: #f3f5f6;
  display: flex;
  align-items: center;
  border-radius: 50px;
  padding: 0 8px;
  height: 34px;
  margin-left: 10px;

  .name {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .gq {
    width: 25px;
    height: 25px;
    border-radius: 100%;
    background: rgba(0, 0, 0, 0.2);
    margin-right: 4px;
  }

  .arrow {
    width: 20px;
    height: 20px;
    background: url("@/assets/images/arrow.svg") no-repeat center center;
    background-size: 60%;
    opacity: 0.3;
  }
}
</style>
